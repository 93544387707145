import React, { useCallback, useMemo, useState } from 'react';
import { Trash } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Toast } from 'reactstrap';

import { IAuthContext } from './interfaces';

export const AuthContext = React.createContext<IAuthContext>({
  login: (_email, _password): Promise<boolean> => Promise.resolve(false),
  logout: (): Promise<void> => Promise.resolve(),
  apiToken: '',
  loggedIn: false,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const [apiToken, setApiToken] = useState<string | null>(localStorage.getItem('apiToken'));
  const [loggedIn, setLoggedIn] = useState<boolean>(!!localStorage.getItem('apiToken'));
  const history = useHistory();
  const { children } = props;

  const logout = useCallback(async (): Promise<void> => {
    setApiToken(null);
    setLoggedIn(false);
    localStorage.removeItem('apiToken');
  }, []);

  const handleLoginSuccess = useCallback((token: string): void => {
    if (token) {
      localStorage.setItem('apiToken', token);
      setLoggedIn(true);
      setApiToken(token);
    }
  }, []);

  const login = useCallback(
    async (email: string, password: string): Promise<boolean> => {
      return new Promise((resolve) => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/sessions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ login: { email, password } }),
        })
          .then((response) => response.json())
          .then((data) => {
            const token = data?.token;
            token && handleLoginSuccess(token);
          })
          .catch((error) => {
            toast.error(<Toast title="Wrong credentials" color="danger" icon={<Trash />} />, {
              icon: false,
              autoClose: 5000,
              hideProgressBar: true,
              closeButton: false,
            });
            console.error('Error:', error);
          });
      });
    },
    [handleLoginSuccess, history]
  );

  const options = useMemo(() => {
    return { apiToken, login, logout, loggedIn };
  }, [apiToken, login, logout, loggedIn]);

  return <AuthContext.Provider value={options}>{children}</AuthContext.Provider>;
};
